import { useEffect, useState } from "react";
import * as IndexedDbData from '../../util/database/dexie.access'

const INITIAL_VALUES = { thoughtInput: ''}

const BrainDump = () => { 
  const [ inputState, setInputState ] = useState(INITIAL_VALUES);
  const [ uncategorizedTasks, setUncategorizedTasks ] = useState([]);

  const fetchUncategorizedTasks = async () => {
    const tasks = (await IndexedDbData.getUncategorizedTasks());
    setUncategorizedTasks(tasks);
  }

  useEffect(() => {
    fetchUncategorizedTasks();
  })

  const dumpThoughtHandler = () => {
    IndexedDbData.createTask(inputState.thoughtInput);
    fetchUncategorizedTasks();
    setInputState(INITIAL_VALUES);
  }

  const onSubmitHandler = (event) => {
    event.preventDefault();
    dumpThoughtHandler();
  }

  const onChangeHandler = (event) => {
    const { value, name } = event.target;
    setInputState({...inputState, [name]: value})
  }

  return (
    <div>
      <p>What's in your mind right now?</p>
      <form onSubmit={onSubmitHandler}>
        <input
          value={inputState.thoughtInput}
          name="thoughtInput"
          onChange={onChangeHandler}
          placeholder="your idea here...">
        </input>
      </form>
      <div>
        <br></br>
        Item count: { uncategorizedTasks.length }
      </div>
    </div>
  )
}

export default BrainDump;