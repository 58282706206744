import { Avatar, Box, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useContext } from "react";
import { UserContext } from "../../contexts/user.context";
import PortraitIcon from '@mui/icons-material/Portrait';
import PeopleIcon from '@mui/icons-material/People';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CloseIcon from '@mui/icons-material/Close';
import SettingsIcon from '@mui/icons-material/Settings';
import InboxIcon from '@mui/icons-material/Inbox';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from "react-router-dom";

const UserMenuDrawer = ({open, onCloseHandler}) => {
  const { userDetails, logOutUser } = useContext(UserContext)

  const navigate = useNavigate()

  return (<>
    <Drawer
      anchor="right"
      open={open}
      onClose={onCloseHandler}
    >
      <Box sx={{ minWidth: '300px' }}>
        <List>
          <ListItem sx={{
            display: 'flex',
            justifyContent: 'space-between'
          }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}> 
              <span style={{ fontWeight: 'bold' }}>{userDetails.name}</span>
              <Avatar sx={{ marginLeft: '10px' }}>{ userDetails.name.toUpperCase().substring(0,2) }</Avatar>
            </Box>
            <CloseIcon
              sx={{ marginLeft: '10px', cursor: 'pointer' }}
              onClick={onCloseHandler}
            />
          </ListItem>
          <ListItem>
            <ListItemButton
              onClick={() => {
                onCloseHandler()
                navigate('/profile')
              }}
            >
              <ListItemIcon><PortraitIcon /></ListItemIcon>
              <ListItemText>Your profile</ListItemText>
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemButton
              onClick={() => {
                onCloseHandler()
                navigate('/inbox')
              }}
            >
              <ListItemIcon><InboxIcon /></ListItemIcon>
              <ListItemText>Your inbox</ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              onClick={() => {
                onCloseHandler()
                navigate('/roles')
              }}
            >
              <ListItemIcon><PeopleIcon /></ListItemIcon>
              <ListItemText>Your roles</ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              onClick={() => {
                onCloseHandler()
                navigate('/mindmap')
              }}
            >
              <ListItemIcon><AccountTreeIcon /></ListItemIcon>
              <ListItemText>Your mindmap</ListItemText>
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemButton
              onClick={() => {
                onCloseHandler()
                navigate('/settings')
              }}
            >
              <ListItemIcon><SettingsIcon /></ListItemIcon>
              <ListItemText>Settings</ListItemText>
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemButton
              onClick={() => {
                logOutUser()
                onCloseHandler()
                navigate('/')
              }}
            >
              <ListItemIcon><LogoutIcon /></ListItemIcon>
              <ListItemText>Logout</ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </Drawer>
  </>)
}

export default UserMenuDrawer;