import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

const SelectTasksHelperDialog = ({open, onCloseHandler}) => {
  return (
    <Dialog
      open={open}
      onClose={onCloseHandler}
    >
      <DialogTitle>
        Selecting tasks
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Now it's time to decide which tasks make it to the next week.
          Take some considerations:
          
            <li>Is it urgent?</li>
            <li>Is it important to me?</li>
            <li>Is it there for a long time already?</li>
          
          Try to not overemphasize one of your roles heavily unless you have good reason to.
          Remember that our brains like to tell us that a specific thing has to be done right now,
          when on the other hand we really should connect to some friends and families
          even if it doesn't feel urgent.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseHandler}>CLOSE</Button>
      </DialogActions>
    </Dialog>
  )
}

export default SelectTasksHelperDialog;