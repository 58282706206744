import { Button, Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material"

const RemoveTaskDialog = ({task, open, onRemoveHandler, onCancelHandler}) => {
  return (
    <Dialog
      open={open}
      onClose={onCancelHandler}
    >
      <DialogTitle>Remove task {task.name}?</DialogTitle>
      <DialogContent>
        <DialogContentText>Do you want to remove the task from your list?</DialogContentText>
        <Button onClick={() => onRemoveHandler()}>Remove</Button>
        <Button onClick={() => onCancelHandler()}>Cancel</Button>
      </DialogContent>
    </Dialog>
  )
}

export default RemoveTaskDialog;