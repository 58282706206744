import { createContext, useState } from "react";

export const PrepareNextWeekContext = createContext();

export const PrepareNextWeekProvider = props => {
  const initialStep = JSON.parse(localStorage.getItem('currentStep')) || 0
  const [ currentStep, setCurrentStep ] = useState(initialStep)

  const proceed = () => {
    const nextStep = currentStep + 1
    localStorage.setItem('currentStep', JSON.stringify(nextStep))
    setCurrentStep(nextStep)
  }

  const goBack = () => {
    if(currentStep === 0) return
    const nextStep = currentStep - 1
    localStorage.setItem('currentStep', JSON.stringify(nextStep))
    setCurrentStep(nextStep)
  }
  
  return (
    <PrepareNextWeekContext.Provider value={{
      currentStep,
      proceed,
      goBack
    }}>
      { props.children }
    </PrepareNextWeekContext.Provider>
  )
}