import { useContext } from "react"
import { UserContext } from "../../contexts/user.context"
import * as Firebase from "../../util/database/dexie.access"
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";

const DeleteTaskDialog = ({open, onCloseHandler, task}) => {
  const { user } = useContext(UserContext);

  if(!task) return <></>

  return (
    <Dialog
      open={open}
      onClose={onCloseHandler}
    >
      <DialogTitle>Do you want to delete '{task.name}'?</DialogTitle>
      <DialogContent>
        <Button
          onClick={() => {
            onCloseHandler()
            Firebase.deleteTask(task, user.uid)
          }}
        >Yes</Button>
        <Button onClick={onCloseHandler}>No</Button>
      </DialogContent>
    </Dialog>
  )
}

export default DeleteTaskDialog;