import { doc, setDoc, updateDoc } from "firebase/firestore"
import { firestore } from "../firestore.setup";


export const setUpUser = async (userObject) => {
  const userDoc = doc(firestore, "users", userObject.uid);
  await setDoc(userDoc, {
    name: userObject.email
  })
}

export const editUserDetails = async (newDetails, userId) => {
  const userDoc = doc(firestore, "users", userId);
  await updateDoc(userDoc, newDetails)
}