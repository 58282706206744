import { useContext, useState } from "react";
import * as IndexedDbData from '../../util/database/dexie.access'
import { Tab, Tabs, Typography } from "@mui/material";
import TaskChecker from "../../components/task-checker/task-checker.component";

import { UserContext } from "../../contexts/user.context";
import { PrepareNextWeekContext } from "../../contexts/prepare-next-week.context";

import TaskSelectedSnackbar from "../../components/task-selected-snackbar/task-selected-snackbar.component";
import SelectTasksHelperDialog from "../../components/select-tasks-helper-dialog/select-tasks-helper-dialog.component";
import ProceedDialog from "../../components/proceed-dialog/proceed-dialog.component";

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import HelpIcon from '@mui/icons-material/Help';
import { TaskContext } from "../../contexts/task.context";

const CreateList = () => {
  const [ currentRoleIndex, setCurrentRoleIndex ] = useState(0);
  const { roles, tasks } = useContext(TaskContext);

  const [ visitedRoles, setVisitedRoles ] = useState(new Set([0]));
  const [ openTaskSelectedSnackbar, setOpenTaskSelectedSnackbar ] = useState(false)
  const [ selectedTaskName, setSelectedTaskName ] = useState('')
  const [ showHelperDialog, setShowHelperDialog ] = useState(false)
  const [ showProceedDialog, setShowProceedDialog ] = useState(false)
  const { user } = useContext(UserContext);
  const { proceed } = useContext(PrepareNextWeekContext);
  
  if(!roles.length) return (<div>no roles available yet</div>)
  
  const tasksToRender = tasks.filter(task => task.roleId === roles[currentRoleIndex].id);
  const selectedTaskCount = tasks.reduce((prev, task) => {
    if(task.candidate) return prev+1;
    return prev;
  }, 0)

  const nextHandler = () => {
    if(visitedRoles.size === roles.length) {
      setShowProceedDialog(true)
      return
    }
    setVisitedRoles(visitedRoles.add((currentRoleIndex + 1) % roles.length))
    setCurrentRoleIndex((currentRoleIndex + 1) % roles.length)
  };
  const prevHandler = () => {
    if(visitedRoles.size === roles.length) {
      setShowProceedDialog(true)
      return
    }
    setVisitedRoles(visitedRoles.add((currentRoleIndex - 1 + roles.length) % roles.length))
    setCurrentRoleIndex((currentRoleIndex - 1 + roles.length) % roles.length);
  }

  const handleAddTodo = async (taskToFlip) => {
    await IndexedDbData.performToggleNextWeekFlagging(taskToFlip, user.uid);
    if(!taskToFlip.candidate) {
      setSelectedTaskName(taskToFlip.name);
      setOpenTaskSelectedSnackbar(true);
    }
  }

  return (
    <div>
      <Typography
        onClick={() => setShowHelperDialog(true)}
        variant="h5" sx={{ textAlign: 'center', cursor: 'pointer' }}
      >
        Need help
        <HelpIcon />
      </Typography>
      <TaskSelectedSnackbar
        open={openTaskSelectedSnackbar}
        taskName={selectedTaskName}
        onCloseHandler={() => setOpenTaskSelectedSnackbar(false)}
      />

      <div>
        <Typography>
          Tasks selected:&nbsp;
          <span style={{ fontWeight: 'bold'}}>
            {selectedTaskCount}
          </span>
        </Typography>
        {roles.length === visitedRoles.size ? <Typography>
          All roles visited!
        </Typography> : ''}
      </div>

      <div
        style={{
          display: 'flex', justifyContent: 'space-evenly', alignItems: 'center'
        }}
      >
        <ArrowBackIosIcon
          onClick={prevHandler}
          type="button"
          sx={{ cursor: 'pointer' }}
          />
        <Tabs
          value={currentRoleIndex}
          variant="scrollable"
          scrollButtons={false}
          onChange={(_, newValue) => {
            if(visitedRoles.size === roles.length) {
              setShowProceedDialog(true)
              return
            }
            setVisitedRoles(visitedRoles.add(newValue))
            setCurrentRoleIndex(newValue)
          }}
          >
          {
            roles.map((role) => (
              <Tab key={role.id} label={role.name} id={role.id} />
              ))
            }
        </Tabs>
        <ArrowForwardIosIcon
          onClick={nextHandler}
          type="button"
          sx={{ cursor: 'pointer' }}
        /> 
      </div>
      <div>
        <Typography
          variant="h5"
          sx={{ textAlign: 'center' }}
        >
          {roles[currentRoleIndex].name}
        </Typography>
        {
          tasksToRender.map(task =>
            <TaskChecker
              key={task.id}
              task={task}
              isTodo={task.candidate}
              onCheckHandler={() => handleAddTodo(task)}
            />
          )
        }
      </div>
      <SelectTasksHelperDialog
        open={showHelperDialog}
        onCloseHandler={() => setShowHelperDialog(false)}
      />
      <ProceedDialog
        open={showProceedDialog}
        onProceedHandler={() => {
          proceed()
          setShowProceedDialog(false)
        }}
      >
        Well done! You've gone through all tasks and prioritized!
      </ProceedDialog>
    </div>
  )
}

export default CreateList;