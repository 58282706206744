import { Box, Button, CardContent, CircularProgress, LinearProgress, Typography } from "@mui/material"
import { useContext, useEffect, useState } from "react";
import { TASK_SIZE } from "../../util/database/constants";
import { toggleIsDone } from '../../util/database/dexie.access';
import RandomTaskDialog from "../random-task-dialog/random-task-dialog.component";
import { useNavigate } from "react-router-dom";
import TaskCompletedSnackbar from "../task-completed-snackbar/task-completed-snackbar.component";
import { UserContext } from "../../contexts/user.context";
import { TaskContext } from "../../contexts/task.context";

const CurrentWeekCard = () => {
  const { todos, todoListReference } = useContext(TaskContext);
  const { user } = useContext(UserContext);
  const [ completedPercentage, setCompletedPercentage ] = useState(0)
  const [ openRandomTask, setOpenRandomTask ] = useState(false)
  const [ openDoneSnackbar, setOpenDoneSnackbar ] = useState(false)
  const [ doneTaskName, setDoneTaskName ] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    if(!todos) return
    const bigTodos = todos.filter(t => t.taskSize === TASK_SIZE.BIG);
    const smallTodos = todos.filter(t => t.taskSize === TASK_SIZE.SMALL);
    const bigTodosCompleted = bigTodos.filter(t => t.done);
    const smallTodosCompleted = smallTodos.filter(t => t.done);
    const newValue = (bigTodosCompleted.length + smallTodosCompleted.length) / todos.length * 100
    setCompletedPercentage(newValue || 0)
  }, [todos])

  if(!todoListReference) return (<></>)
  if(todos === undefined) return (<CircularProgress />)

  const bigTodos = todos.filter(t => t.taskSize === TASK_SIZE.BIG);
  const smallTodos = todos.filter(t => t.taskSize === TASK_SIZE.SMALL);
  const bigTodosCompleted = bigTodos.filter(t => t.done);
  const smallTodosCompleted = smallTodos.filter(t => t.done);
  const openTodo = todos.filter(t => !t.done)[0];

  const todoListCreated = todoListReference.created.toDate();

  const deadline = todoListReference.created.toDate();
  deadline.setDate(deadline.getDate() + 7);
  const daysLeft = Math.floor((deadline - new Date()) / (1000 * 60 * 60 * 24));

  return (
  <>
  <RandomTaskDialog
    open={openRandomTask}
    task={openTodo}
    handleClose={() => setOpenRandomTask(false)}
    handleComplete={() => {
      toggleIsDone(openTodo, user.uid)
      setDoneTaskName(todos[0].name)
      setOpenDoneSnackbar(true)
    }}
  />
  <TaskCompletedSnackbar
    openDoneSnackbar={openDoneSnackbar}
    onCloseHandler={() => setOpenDoneSnackbar(false)}
    doneTaskName={doneTaskName}
  />
  <CardContent
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      height: '100%'
    }}
  >
    <Typography color="primary" variant="h5">Current week</Typography>
    <Typography
      color="text.secondary"
      sx={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <span>Big tasks:</span>
      <span>{bigTodosCompleted.length} / {bigTodos.length}</span>
    </Typography>
    <Typography
      color="text.secondary"
      sx={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <span>Small tasks:</span>
      <span>{smallTodosCompleted.length} / {smallTodos.length}</span>
    </Typography>
    <Typography
      color="text.secondary"
      sx={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <span>Overall:</span>
      <span>
        {bigTodosCompleted.length + smallTodosCompleted.length} /{` `}
        {bigTodos.length + smallTodos.length}
      </span>
    </Typography>
    <LinearProgress variant="determinate" value={completedPercentage} />
    <Typography
      color="text.secondary"
      sx={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <span>Start:</span>
      <span>
        {JSON.stringify(todoListCreated.getDate())}.
        {JSON.stringify(todoListCreated.getMonth()+1)}.
        {JSON.stringify(todoListCreated.getFullYear())}
      </span>
    </Typography>
    <Typography
      color={daysLeft < 1 ? "warning.main" : "text.secondary"}
      sx={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <span>Days left:</span>
      <span>
        {daysLeft}
      </span>
    </Typography>
    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
      <Button
        variant="outlined"
        sx={{ marginTop: '10px' }}
        onClick={() => setOpenRandomTask(true)}
      >
        GIVE ME A QUICK ONE
      </Button>
      <Button
        variant="outlined"
        sx={{ marginTop: '10px' }}
        onClick={() => navigate('current-todo-list')}
      >
        VIEW LIST
      </Button>
      <Button
        variant="outlined"
        sx={{ marginTop: '10px' }}
        onClick={() => navigate('finish-week')}
      >
        FINISH WEEK
      </Button>
    </Box>
  </CardContent></>)
}

export default CurrentWeekCard;