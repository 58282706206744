import { useContext, useState } from "react";
import TaskPreview from "../../components/task-preview/task-preview.component";
import { TASK_SIZE } from "../../util/database/constants"
import * as IndexedDbData from '../../util/database/dexie.access'
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/user.context";
import HelpIcon from '@mui/icons-material/Help';
import ReviewListHelperDialog from "../../components/review-list-helper-dialog/review-list-helper-dialog.component";
import { TaskContext } from "../../contexts/task.context";

const ConfirmList = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { tasks } = useContext(TaskContext);
  const [ showHelperDialog, setShowHelperDialog ] = useState(false)

  const flaggedTasks = tasks.filter(t => t.candidate);
  const uncategorized = flaggedTasks.find(t => !t.taskSize);

  if(uncategorized !== undefined) return (<div>categorizing not finished - return to categorizing</div>)

  const smallTasks = flaggedTasks.filter(task =>
    'taskSize' in task
    ? task.taskSize === TASK_SIZE.SMALL
    : false
  )
  const bigTasks = flaggedTasks.filter(task =>
    'taskSize' in task
    ? task.taskSize === TASK_SIZE.BIG
    : false
  )

  const handleTodoListSubmit = () => {
    localStorage.setItem('currentStep', '0')
    IndexedDbData.createTodoList(flaggedTasks, user.uid);
    navigate('/');
  }

  return (
    <>
    <Typography
      onClick={() => setShowHelperDialog(true)}
      variant="h5" sx={{ textAlign: 'center', cursor: 'pointer' }}
    >
      <HelpIcon />
    </Typography>
    <Box className='task-category-container' sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
      <Box>
        <h2 style={{ textAlign: 'center' }}>Small Gysts</h2>
        {smallTasks.map(task => <TaskPreview key={task.id} task={task} />)}
      </Box>
      <Box sx={{ width: '20px' }}>
      </Box>
      <Box>
        <h2 style={{ textAlign: 'center' }}>Big Gysts</h2>
        {bigTasks.map(task => <TaskPreview key={task.id} task={task} />)}
      </Box>
    </Box>
    <Box sx={{ textAlign: 'center' }}>
      <p>is that good?</p>
      <Button onClick={() => handleTodoListSubmit()} type="button">
        Commit to the list
      </Button>
    </Box>
    <ReviewListHelperDialog
      open={showHelperDialog}
      onCloseHandler={() => setShowHelperDialog(false)}
    />
    </>
  )
}

export default ConfirmList;