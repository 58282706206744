import { Typography } from "@mui/material"
import { useContext } from "react"
import { TaskContext } from "../../contexts/task.context"
import { useNavigate, useParams } from "react-router-dom"
import "./mindmap-role.style.css"

const MARGIN_BOTTOM = 15
const HEIGHT = 40
const WIDTH = 260
const MAX_DISTANCE = 40
const CURVE_DISTANCE = 50
const center = {x: 150, y: 220}
const startpoint = { x: 150 + center.x, y: 100 + center.y }

const MindmapRole = () => {
  const { tasks, roles } = useContext(TaskContext);
  const { roleId } = useParams();
  const navigate = useNavigate();

  if(tasks === undefined) return <>Loading...</>
  if(roles === undefined) return <>Loading...</>
  const role = roles.find(r => r.id === roleId);
  const selectedTasks = tasks.filter(t => t.roleId === roleId)

  const rolesPerSide = Math.ceil(selectedTasks.length / 2)
  let offset = 0
  if(rolesPerSide % 2 === 0) offset = -HEIGHT / 2
  let top = offset +  Math.floor(rolesPerSide / 2) * (HEIGHT + MARGIN_BOTTOM);

  const positions = [];
  for(let i = 0; i < rolesPerSide; i++) {
    const distanceVariation = i < rolesPerSide/2 ? i : rolesPerSide - i - 1
    positions.push({
      x: MAX_DISTANCE * distanceVariation * 0.5,
      y: -top + i * 2 * (HEIGHT + MARGIN_BOTTOM) / 2
    })
  }

  const allPositions = positions.concat(positions)

  const MAGIC_TOP = 100 - Math.ceil((rolesPerSide - 2) / 2) * 20
    - Math.ceil((rolesPerSide - 1) / 2) * 35

  return (
  <>
    <Typography sx={{ textAlign: 'center', color: 'white' }} variant="h4">Mindmap</Typography>
    <div className="mindmap-mobile">Mindmap is not available on mobile devices</div>
    <div className="mindmap">
      <div
        className="svg-container"
      >
        <svg className="svg-element">
          {selectedTasks.map((r, index) => {
            // refactor properly... center is not the center.. weird offset
            const endpoint = {
              x: (index < rolesPerSide) ?
                center.x + 185 + 100 - WIDTH - allPositions[index].x :
                center.x + 115 - 100 + WIDTH + allPositions[index].x,
              y: allPositions[index].y+top+MAGIC_TOP+center.y
            }

            const control1 = {
              x: (index < rolesPerSide) ?
                center.x + 65 + CURVE_DISTANCE - allPositions[index].x :
                center.x + 235 - CURVE_DISTANCE + allPositions[index].x,
              y: allPositions[index].y+top+MAGIC_TOP+center.y
            }

            const control2 = {
              x: (index < rolesPerSide) ?
                center.x + 65 + CURVE_DISTANCE - allPositions[index].x :
                center.x + 235 - CURVE_DISTANCE + allPositions[index].x,
              y: allPositions[index].y+top+MAGIC_TOP+center.y
            }

            return (<path
              key={r.id}
              d={`M ${startpoint.x} ${startpoint.y} C ${control1.x} ${control1.y}, ${control2.x} ${control2.y}, ${endpoint.x} ${endpoint.y}`}
              fill="none"
              stroke="white"
              strokeWidth="1"
            />)
          })}
        </svg>
      </div>
      <div className="me"
        style={{
          height: `${HEIGHT}px`,
          lineHeight: `${HEIGHT}px`,
          width: `${WIDTH}px`,
        }}
      >
        <span className="pointer mindmap-center" onClick={() => navigate('/mindmap')}>
          ME :: {role.name}
        </span>
        {selectedTasks.map((r, index) => 
          <div
            key={r.id}
            className={`task ${index < rolesPerSide ? "right" : "left"}`}
            style={{
              lineHeight: `${HEIGHT}px`,
              width: `${WIDTH}px`,
              top: `${allPositions[index].y}px`,
              left: (index < rolesPerSide) ?
                `${-WIDTH-0 - allPositions[index].x}px` :
                `${WIDTH+0 + allPositions[index].x}px`
          }}>
            <div
              className={r.candidate ? "candidate-task" : ""}
              style={{ overflow: "visible", whiteSpace: "nowrap"}}>{r.name}
            </div>
          </div>
        )}
      </div>
    </div>
  </>
  )
}

export default MindmapRole;