import { Box, Checkbox, Typography } from '@mui/material';
import './task-checker.styles.css';

const TaskChecker = ({task, isDone, isTodo, onCheckHandler}) => {
  return (
    <Box
      className={"task-checker-container"}
      onClick={(event) => {
        event.stopPropagation();
        onCheckHandler();
      }}
      sx={{ cursor: 'pointer', display: 'flex', alignItems: 'left' }}>
      <Checkbox
        color='primary'
        readOnly
        checked={isDone || isTodo || false}
        sx={{ '& .MuiSvgIcon-root': { fontSize: 16 } }}
      >
      </Checkbox>
      <Typography
        sx={{ margin: 'auto 0'}}
      >{task.name}</Typography>
    </Box>
  )
}

export default TaskChecker;