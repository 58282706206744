import RoleSelector from '../role-selector/role-selector.component';
import './role-selection.styles.css';
import * as IndexedDbData from '../../util/database/dexie.access'
import { Box } from '@mui/material';
import { useContext } from 'react';
import { UserContext } from '../../contexts/user.context';
import RoleCreator from '../role-creator/role-creator.component';

const RoleSelection = ({taskToSort, roles}) => {
  const { user } = useContext(UserContext);

  const handleRoleAssignment = (taskToAssign, role) => {
    IndexedDbData.assignTaskToRole(taskToAssign, role, user.uid)
  }

  return (
    <Box sx={{ marginTop: '20px', display: 'flex', flexWrap: 'wrap' }}>
      {roles.map(role =>
        <RoleSelector
          key={role.id}
          role={role}
          onSelectHandler={() => handleRoleAssignment(taskToSort, role)}
        />)}
      <RoleCreator />
    </Box>
  )
}

export default RoleSelection;