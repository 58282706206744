import { Button, Dialog, DialogContent, DialogContentText } from "@mui/material"

const ProceedDialog = ({open, onProceedHandler, children}) => {
  return(
    <Dialog
      open={open}
    >
      <DialogContent>
        <DialogContentText>
          {children}
        </DialogContentText>
        <Button onClick={onProceedHandler}>Proceed?</Button>
      </DialogContent>
    </Dialog>
  )
}

export default ProceedDialog;