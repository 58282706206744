import { Box, Button, Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import { useNavigate } from "react-router-dom"

const RandomTaskDialog = ({ open, handleClose, handleComplete, task }) => {
  const navigate = useNavigate()

  if(!task) return (<Dialog open={open} onClose={handleClose}>
    <DialogTitle>No more tasks to do!</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Congratulations! There is nothing more to do!
        Why not reflect your week and start planning the next one?
      </DialogContentText>
      <Box>
        <Button onClick={handleClose}>
          CLOSE
        </Button>
        <Button onClick={() => navigate('/finish-week')}>
          OKAY
        </Button>
      </Box>
    </DialogContent>
  </Dialog>)

  return(
    <Dialog open={open}>
      <DialogTitle>
        {task.name}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`Some more detailed information that's needed to complete the task.`} 
        </DialogContentText>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={() => {
            handleClose()
            handleComplete()
          }}>
            COMPLETE
          </Button>
          <Button onClick={handleClose}>
            CLOSE
          </Button>
        </Box>
      </DialogContent>
    </Dialog>)
}

export default RandomTaskDialog;