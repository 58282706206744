import { Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import './task-preview.styles.css';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { TASK_SIZE } from '../../util/database/constants'
import { useContext, useEffect, useState } from 'react';
import EditTaskDialog from '../edit-task-dialog/edit-task-dialog.component'
import RemoveTaskDialog from '../remove-task-dialog/remove-task-dialog.component';
import { updateTask } from '../../util/database/dexie.access';
import { UserContext } from '../../contexts/user.context';

const TaskPreview = ({task}) => {
  const [ showEditTaskDialog, setShowEditTaskDialog ] = useState(false)
  const [ taskObject, setTaskObject ] = useState(task)
  const [ showRemoveTaskDialog, setShowRemoveTaskDialog ] = useState(false)
  const { user } = useContext(UserContext)

  useEffect(() => setTaskObject(task), [task])

  const setTaskSize = async (taskSize) => {
    await updateTask({...taskObject, taskSize}, user.uid)
  }

  return (
    <>
      <div style={{
        width: '300px',
        padding: '5px',
        paddingLeft: '8px',
        paddingRight: '8px',
        marginBottom: '5px',
        backgroundColor: '#efefef',
        borderRadius: '5px',
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        <div style={{
          display: 'flex',
          alignItems: 'center'
        }}>
          {taskObject.taskSize === TASK_SIZE.BIG ?
            <ArrowLeftIcon
              sx={{ cursor: 'pointer' }}
              onClick={() => setTaskSize(TASK_SIZE.SMALL)}
            /> : ''
          }
          <Typography sx={{ display: 'inline' }}>
            { taskObject.name }
          </Typography>
        </div>
        <div style={{
          display: 'flex',
          alignItems: 'center'
        }}>
          <EditIcon
            sx={{ cursor: 'pointer' }}
            onClick={() => setShowEditTaskDialog(true)}
          />
          <ClearIcon
            sx={{ cursor: 'pointer' }}
            onClick={() => setShowRemoveTaskDialog(true)}
          />
          {taskObject.taskSize === TASK_SIZE.SMALL ?
            <ArrowRightIcon
              sx={{ cursor: 'pointer' }}
              onClick={() => setTaskSize(TASK_SIZE.BIG)}
            /> : ''
          }
        </div>
      </div>

      <EditTaskDialog
        task={task}
        open={showEditTaskDialog}
        onCloseHandler={(updatedTask)=> {
          setTaskObject(updatedTask)
          setShowEditTaskDialog(false)
        }}
      />

      <RemoveTaskDialog
        task={taskObject}
        onCancelHandler={() => setShowRemoveTaskDialog(false)}
        onRemoveHandler={() => {
          updateTask({...taskObject, candidate: false }, user.uid)
          setShowRemoveTaskDialog(false)
        }}
        open={showRemoveTaskDialog}
      />
    </>
  )
}

export default TaskPreview;