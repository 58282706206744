import { collection, query, where } from "firebase/firestore"
import { firestore } from "./firestore.setup"

// all active tasks (not archived)
export const getActiveTasksCollection = (uid) => {
  return query(collection(firestore, "userData", uid, "tasks"), where("completed", "!=", true));
}

// all roles
export const getRolesCollection = (uid) => {
  return collection(firestore, "userData", uid, "roles");
}

// the active todolist
export const getTodoListsCollection = (uid) => {
  return query(collection(firestore, "userData", uid, "todoLists"), where("finished", "==", false))
}

export const getCurrentTodosCollection = (uid, todoListId) => {
  return query(collection(firestore, "userData", uid, "todos"), where("todoListId", "==", todoListId));
}