import { Card, CardContent } from '@mui/material';
import './role-selector.styles.css';

const RoleSelector = ({role, onSelectHandler}) => {
  return (
    <Card
      className='role-selector-container'
      onClick={onSelectHandler}
      sx={{
        cursor: 'pointer',
        maxWidth: '350px',
        maxHeight: '150px',
        margin: '10px auto',
        backgroundColor: '#efefef'
      }}
    >
      <CardContent>{ role.name }</CardContent>
    </Card>
  )
}

export default RoleSelector;