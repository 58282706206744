import { Button, Dialog, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../contexts/user.context";
import { editUserDetails } from "../../util/database/firestore.access";

const EditUsernameDialog = ({username, open, onCloseHandler}) => {
  const { user } = useContext(UserContext);
  const [ currentName, setCurrentName ] = useState(username)

  useEffect(() => setCurrentName(username), [username])

  return (
    <Dialog
      open={open}
      onClose={() => {
        editUserDetails({ name: currentName }, user.uid)
        onCloseHandler()
      }}
    >
      <DialogTitle>
        Edit name
      </DialogTitle>
      <DialogContent>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            editUserDetails({ name: currentName }, user.uid)
            onCloseHandler()
          }}
        >
          <TextField
            sx={{ marginTop: '5px' }}
            onChange={(event) => setCurrentName(event.target.value)}
            value={currentName}
            label="Name"
            autoComplete="off"
          />
          <br />
          <Button type="submit">Edit</Button>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default EditUsernameDialog;