import { useContext, useState } from "react";
import { TASK_SIZE } from "../../util/database/constants"
import TaskChecker from "../../components/task-checker/task-checker.component";
import { UserContext } from "../../contexts/user.context"
import * as IndexedDbData from '../../util/database/dexie.access'
import { Card, CardContent, Typography } from "@mui/material";
import TaskCompletedSnackbar from "../../components/task-completed-snackbar/task-completed-snackbar.component";
import { TaskContext } from "../../contexts/task.context";

const CurrentTodoList = () => {
  const { user } = useContext(UserContext)
  const { todos, todoListReference } = useContext(TaskContext);
  const [ openDoneSnackbar, setOpenDoneSnackbar ] = useState(false)
  const [ doneTaskName, setDoneTaskName ] = useState('')

  if(todos === undefined) return <>Loading...</>
  if(todoListReference === undefined) return <>Loading...</>

  const bigTodos = todos.filter(t => t.taskSize === TASK_SIZE.BIG);
  const smallTodos = todos.filter(t => t.taskSize === TASK_SIZE.SMALL);

  const handleDone = async (task) => {
    await IndexedDbData.toggleIsDone(task, user.uid);
  }

  if(!todoListReference) return (<div>there is no current todo list - select some tasks and submit your list</div>);

  return (
    <>
    <TaskCompletedSnackbar
      openDoneSnackbar={openDoneSnackbar}
      onCloseHandler={() => setOpenDoneSnackbar(false)}
      doneTaskName={doneTaskName}
    />
    <Card
      sx={{ width: '80%', maxWidth: '400px', margin: 'auto' }}
    >
      <CardContent>
        <Typography
          color="primary"
          variant="h4"
          textAlign='center'
        >
          Current week
        </Typography>
        <Typography>Calendar week: ??</Typography>
        <Typography
          color="primary"
          variant="h5"
          sx={{ marginBottom: '10px' }}
        >
          Big Gysts
        </Typography>
        {
          bigTodos.map(task => (
            <TaskChecker
              key={task.id}
              task={task}
              isDone={task.done}
              onCheckHandler={() => {
                if(task.done === false) {
                  setOpenDoneSnackbar(true)
                  setDoneTaskName(task.name)
                }
                handleDone(task)
              }}
            />
          ))
        }
        <Typography
          variant="h5"
          color="primary"
          sx={{ marginBottom: '10px', marginTop: '10px' }}
        >
          Small Gysts
        </Typography>
        {
          smallTodos.map(task => (
            <TaskChecker
              key={task.id}
              task={task}
              isDone={task.done}
              onCheckHandler={() => {
                if(task.done === false) {
                  setOpenDoneSnackbar(true)
                  setDoneTaskName(task.name)
                }
                handleDone(task)
              }}
            />
          ))
        }
      </CardContent>
    </Card>
    </>
  )
}

export default CurrentTodoList;