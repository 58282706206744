import { Button, CardContent, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom";

const StartNewWeekCard = () => {
  const navigate = useNavigate();

  const currentStep = localStorage.getItem('currentStep')

  return (
    <CardContent sx={{ maxWidth: '345px'}}>
      <Typography variant="h5">Start a new week!</Typography>
      <Typography color="text.secondary">
        You finished your last week. Lets head towards another week!
        Lets prioritise, organise and prepare for the next week!
      </Typography>
      <Button
        onClick={() => navigate('prepare')}
      >
        { !currentStep ? "Lets go!" : "Continue" }
      </Button>
    </CardContent>
  )
}

export default StartNewWeekCard;