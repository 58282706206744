import { Route, Routes } from 'react-router-dom';
import Navigation from './sites/navigation/navigation.component';

import './App.css';
import BrainDump from './sites/braindump/braindump.component';
import SortingToRole from './sites/sorting-to-role/sorting-to-role.component'
import Clear from './sites/__clear/__clear.component'
import CreateList from './sites/create-list/create-list.component';
import TodoListCategorizer from './sites/todo-list-categorizer/todo-list-categorizer.component';
import ConfirmList from './sites/confirm-list/confirm-list.component';
import CurrentTodoList from './sites/current-todo-list/current-todo-list.component';
import FinishWeek from './sites/finish-week/finish-week.component';
import LandingPage from './sites/landing-page/landing-page';
import PrepareNextWeek from './sites/prepare-next-week/prepare-next-week.component';
import AuthPage from './sites/auth-page/auth-page.component';
import UserProfilePage from './sites/user-profile-page/user-profile-page';
import { PrepareNextWeekProvider } from './contexts/prepare-next-week.context';
import Inbox from './sites/inbox/inbox.site';
import Roles from './sites/roles/roles.site';
import Role from './sites/role/role.site';
import MindmapSite from './sites/mindmap/mindmap.site';
import Settings from './sites/settings/settings.site';
import MindmapRole from './sites/mindmap-role/mindmap-role.site';

const App = () => {
  return (
    <div className='App'>
      <Routes>
        <Route path='/' element={<Navigation />}>
          <Route index element={<LandingPage />}/>
          <Route path='braindump' element={<BrainDump />} />
          <Route path='sorting' element={<SortingToRole />} />
          <Route path='create-list' element={<CreateList />} />
          <Route path='auth' element={<AuthPage />} />
          <Route path='todo-list-categorizer' element={<TodoListCategorizer />} />
          <Route path='confirm-list' element={<ConfirmList />} />
          <Route path='current-todo-list' element={<CurrentTodoList />} />
          <Route path='finish-week' element={<FinishWeek />} />
          <Route path='clear' element={<Clear />} />
          <Route path='prepare' element={
            <PrepareNextWeekProvider>
              <PrepareNextWeek />
            </PrepareNextWeekProvider>
          } />
          <Route path='profile' element={<UserProfilePage />} />
          <Route path='inbox' element={<Inbox />} />
          <Route path='roles' element={<Roles />} />
          <Route path='/role/:roleId' element={<Role />} />
          <Route path='/mindmap' element={<MindmapSite />} />
          <Route path='/mindmap/:roleId' element={<MindmapRole />} />
          <Route path='/settings' element={<Settings />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
