import { useContext, useState } from "react";
import * as IndexedDbData from '../../util/database/dexie.access'
import { useNavigate } from "react-router-dom";
import { Box, Button, Card, CardContent, Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { UserContext } from "../../contexts/user.context";
import TaskChecker from "../../components/task-checker/task-checker.component";
import { TaskContext } from "../../contexts/task.context";

const FinishWeek = () => {
  const { user } = useContext(UserContext);
  const { todos, todoListReference } = useContext(TaskContext);
  const [ showWeekCompleteDialog, setShowWeekCompleteDialog ] = useState(false)
  const navigate = useNavigate();

  if(todoListReference === undefined) return <>Loading...</>
  if(todos === undefined) return <>Loading...</>
  if(!todoListReference) return ('Nothing here...')

  const doneTodos = todos.reduce((acc, todo) => {
      if (todo.done) return acc+1;
    return acc;
  }, 0)
  const undoneTodos = todos.length - doneTodos;

  const handleFinishWeek = async () => {
    await IndexedDbData.finishTodoList(user.uid);
  }

  const handleDone = async (task) => {
    await IndexedDbData.toggleIsDone(task, user.uid);
  }

  return (
    <Box>
      <Card sx={{ maxWidth: '400px', width: '80%', margin: 'auto' }}>
        <CardContent>
            <h1 style={{ textAlign: 'center' }}>Finish your week</h1>
            <p>Finished gysts: { doneTodos }</p>
            <p>Unfinished gysts: { undoneTodos }</p>
            <p>Last chance to get rid of them!</p>
            <Box>
              {todos.filter(t => !t.done).map(t =>
                <TaskChecker
                task={t}
                key={t.id}
                isDone={t.done}
                onCheckHandler={() => handleDone(t)}
                />
                )}
            </Box>
            <Button onClick={() => {
              setShowWeekCompleteDialog(true)
            }} type="button">FINISH YOUR WEEK</Button>
          </CardContent>
      </Card>

      <Dialog
        open={showWeekCompleteDialog}
      >
        <DialogTitle>
          Week completed!
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Well done! You've finished your week!
            Let's set up the next week.
          </DialogContentText>
          <Button onClick={async () => {
            await handleFinishWeek()
            navigate('/')
          }}>
            Return to Home
          </Button>
        </DialogContent>
      </Dialog>
    </Box>
  )
}

export default FinishWeek;