import { createContext, useContext, useEffect, useState } from "react";
import { UserContext } from "./user.context";
import { getActiveTasksCollection, getCurrentTodosCollection, getRolesCollection, getTodoListsCollection } from "../util/firestore.collections";
import { onSnapshot } from "firebase/firestore";

export const TaskContext = createContext();

export const TaskProvider = props => {
  const { user } = useContext(UserContext);
  const [ tasks, setTasks ] = useState(undefined);
  const [ roles, setRoles ] = useState([]);
  const [ todoListReference, setTodoListReference ] = useState(undefined);
  const [ todos, setTodos ] = useState(undefined);

  useEffect(() => {
    if(!user) return;

    const tasksUnsubscribe = onSnapshot(getActiveTasksCollection(user.uid), tasks => {
      const taskObjects = tasks.docs.map(t => ({
        ...(t.data()),
        id: t.id
      }))
      setTasks(taskObjects);
    })

    const rolesUnsubscribe = onSnapshot(getRolesCollection(user.uid), roles => {
      const rolesObjects = roles.docs.map(r => ({
        ...(r.data()),
        id: r.id
      }))
      setRoles(rolesObjects)
    })

    const todoListUnsubscribe = onSnapshot(getTodoListsCollection(user.uid), roles => {
      if(roles.docs.length === 1) {
        setTodoListReference({
          ...(roles.docs[0].data()),
          id: roles.docs[0].id
        })
      }

      if(!roles.docs.length) setTodoListReference(null);
    })

    return () => {
      tasksUnsubscribe();
      rolesUnsubscribe();
      todoListUnsubscribe();
    }
  }, [user])

  useEffect(() => {
    if(!user) return
    if(!todoListReference) return
    const todosUnsubscribe = onSnapshot(
      getCurrentTodosCollection(user.uid, todoListReference.id), todos => {
        // important values of the task should be copied to the todo object
        // on its creation rather than merging that data
        const todoObjects = todos.docs.map(todo => ({
            ...(tasks.find(task => task.id === todo.data().taskId)),
            ...todo.data(),
            id: todo.id
        }))

        setTodos(todoObjects);
      }
    )

    return () => {
      todosUnsubscribe();
    }
  }, [todoListReference, user, tasks])

  return (
    <TaskContext.Provider
      value={{
        tasks,
        roles,
        todoListReference,
        todos
      }}
    >
      {props.children}
    </TaskContext.Provider>
  )
}