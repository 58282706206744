import { Button, Dialog, DialogContent, DialogTitle, TextField } from "@mui/material";
import { updateTask } from "../../util/database/dexie.access";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../contexts/user.context";

const EditTaskDialog = ({task, open, onCloseHandler}) => {
  const { user } = useContext(UserContext);
  const [ currentTaskName, setCurrentTaskName ] = useState(task ? task.name : undefined)

  useEffect(() => {
    if(!task) return
    setCurrentTaskName(task.name)
  }, [task])

  if(currentTaskName === undefined) return <></>

  return (
    <Dialog
      open={open}
      onClose={() => {
        updateTask({...task, name: currentTaskName}, user.uid)
        onCloseHandler({...task, name: currentTaskName})
      }}
    >
      <DialogTitle>
        Edit task
      </DialogTitle>
      <DialogContent>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            updateTask({...task, name: currentTaskName}, user.uid)
            onCloseHandler({...task, name: currentTaskName})
          }}
        >
          <TextField
            sx={{ marginTop: '5px', width: '300px' }}
            onChange={(event) => setCurrentTaskName(event.target.value)}
            value={currentTaskName}
            label="Task name"
            autoComplete="off"
          />
          <br />
          <Button type="submit">Save</Button>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default EditTaskDialog;