import { Button, Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import { useContext } from "react"
import { TaskContext } from "../../contexts/task.context"
import * as Firebase from "../../util/database/dexie.access"
import { UserContext } from "../../contexts/user.context"

const ArchiveRoleDialog = ({open, onCloseHander, role}) => {
  const { tasks } = useContext(TaskContext);
  const { user } = useContext(UserContext);

  if(!role) return <></>

  const openTasks = tasks.filter(t => t.roleId === role.id).length

  if(openTasks > 0) return (
    <Dialog
      open={open}
      onClose={onCloseHander}
    >
      <DialogTitle>Archive not possible</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You can only archive roles without any active tasks.
          Delete, complete or move all tasks to another role first.
        </DialogContentText>
        <Button onClick={onCloseHander}>OK</Button>
      </DialogContent>
    </Dialog>
  )

  return (
    <Dialog
      open={open}
      onClose={onCloseHander}
    >
      <DialogTitle>Archive {role.name}?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Button onClick={() => {
            onCloseHander()
            Firebase.deleteRole(role, user.uid)
          }}>Yes</Button>
          <Button
            onClick={onCloseHander}
          >No</Button>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}

export default ArchiveRoleDialog;