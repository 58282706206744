import { Card, CardContent, Step, StepLabel, Stepper } from "@mui/material"
import { useContext } from "react"
import SortingToRole from "../sorting-to-role/sorting-to-role.component"
import CreateList from "../create-list/create-list.component"
import TodoListCategorizer from "../todo-list-categorizer/todo-list-categorizer.component"
import ConfirmList from "../confirm-list/confirm-list.component"
import { PrepareNextWeekContext } from "../../contexts/prepare-next-week.context"
import { TaskContext } from "../../contexts/task.context"

// in future this would also include
// - make a task a project
// - handling the horizon
// - splitting a task
const steps = [
  'Sorting',
  'Selecting',
  'Sizing',
  'Review'
]

const PrepareNextWeek = () => {
  const { currentStep } = useContext(PrepareNextWeekContext);
  const { tasks } = useContext(TaskContext);

  return (
  <>
    <Stepper
      activeStep={currentStep}
      sx={{ width: '60%', margin: 'auto', marginBottom: '20px' }}
      alternativeLabel
    >
      {steps.map((step) => (
        <Step key={step}>
          <StepLabel>{step}</StepLabel>
        </Step>
      ))}
    </Stepper>
    <Card className='step-container' sx={{ width: '60%', margin: 'auto', marginBottom: '15px' }}>
      <CardContent>
        {tasks !== undefined &&
        <>
          {currentStep === 0 ? <SortingToRole /> : ''}
          {currentStep === 1 ? <CreateList /> : ''}
          {currentStep === 2 ? <TodoListCategorizer /> : ''}
          {currentStep === 3 ? <ConfirmList /> : ''}
        </>}
        {tasks === undefined && <>Loading...</>}
      </CardContent>
    </Card>
  </>)
}

export default PrepareNextWeek;