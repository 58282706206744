import { Button, Dialog, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../contexts/user.context";
import * as Firebase from "../../util/database/dexie.access";

const EditRoleDialog = ({role, open, onCloseHandler}) => {
  const [ currentRoleName, setCurrentRoleName ] = useState("");
  const { user } = useContext(UserContext);

  useEffect(() => {
    if(!role) return
    setCurrentRoleName(role.name)
  }, [role])

  return (
    <Dialog
      open={open}
      onClose={onCloseHandler}
    >
      <DialogContent>
        <DialogTitle>Edit role</DialogTitle>
        <DialogContent>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            Firebase.updateRole({...role, name: currentRoleName}, user.uid);
            onCloseHandler()
          }}
        >
          <TextField
            sx={{ marginTop: '5px', width: '300px' }}
            onChange={(event) => setCurrentRoleName(event.target.value)}
            value={currentRoleName}
            label="Role name"
            autoComplete="off"
          />
          <br />
          <Button type="submit">Save</Button>
        </form>
        </DialogContent>
      </DialogContent>
    </Dialog>
  )
}

export default EditRoleDialog;