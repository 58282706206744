import { Avatar, Box, Typography } from "@mui/material"
import { useContext, useState } from "react"
import { UserContext } from "../../contexts/user.context"
import UserMenuDrawer from "../user-menu-drawer/user-menu-drawer.component";
import "./current-user-icon.styles.css"

const CurrentUserIcon = () => {
  const { user, userDetails } = useContext(UserContext)
  const [ displayUserDrawer, setDisplayUserDrawer ] = useState(false)

  if(!user) return (<></>)

  return (
    <>
    <Box
      onClick={() => { setDisplayUserDrawer(true) }}
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        float: 'right',
        marginRight: '10px',
        marginTop: '10px',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
      }}
    >
      <Typography
        className="hidden-on-mobile"
        sx={{ marginRight: '10px' }}>Hello, { userDetails.name }
      </Typography>
      <Avatar className="current-user-avatar">{ userDetails.name.toUpperCase().substring(0,2) }</Avatar>
    </Box>

    <UserMenuDrawer
      open={displayUserDrawer}
      onCloseHandler={() => setDisplayUserDrawer(false)}
    />
    </>
  )
}

export default CurrentUserIcon;