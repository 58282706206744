import { useContext, useState } from "react";
import { TASK_SIZE } from "../../util/database/constants";
import TaskCategorizer from "../../components/task-categorizer/task-categorizer.component";
import { Box, Typography } from "@mui/material";
import SizingTasksHelperDialog from "../../components/sizing-tasks-helper-dialog/sizing-tasks-helper-dialog.component";
import HelpIcon from '@mui/icons-material/Help';
import ProceedDialog from "../../components/proceed-dialog/proceed-dialog.component";
import { PrepareNextWeekContext } from "../../contexts/prepare-next-week.context";
import { TaskContext } from "../../contexts/task.context";
import TaskPreview from "../../components/task-preview/task-preview.component"

const TodoListCategorizer = () => {
  const { tasks, todoListReference } = useContext(TaskContext);
  const { proceed } = useContext(PrepareNextWeekContext);
  const [ showHelperDialog, setShowHelperDialog ] = useState(false);

  const flaggedTasks = tasks.filter(t => t.candidate)
  const uncategorizedTodos = flaggedTasks.filter(task => !('taskSize' in task));
  const bigTasks = flaggedTasks.filter(task =>
    'taskSize' in task ? task.taskSize === TASK_SIZE.BIG : false);
  const smallTasks = flaggedTasks.filter(task =>
    'taskSize' in task ? task.taskSize === TASK_SIZE.SMALL : false);
  
  if(todoListReference) return (<div>There is an active todolist.</div>)
  
  return (
    <>
      <Typography
      onClick={() => setShowHelperDialog(true)}
      variant="h5" sx={{ textAlign: 'center', cursor: 'pointer' }}
      >
        Need help
        <HelpIcon />
      </Typography>
    <Box sx={{ textAlign: 'center' }}>
      {uncategorizedTodos.length !== 0 ? <h2>Task to sort</h2> : '' }
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center' }}>
        {/* {uncategorizedTodos.map(task => (
          <TaskCategorizer key={task.id} task={task} refresh={fetchFlaggedTasks} />
        ))} */}
        {uncategorizedTodos.length ? <TaskCategorizer
          key={uncategorizedTodos[0].id}
          task={uncategorizedTodos[0]}
          refresh={() => {}}
        /> : ''}
      </Box>
    </Box>
    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>   
      <Box>
        <h2>Small tasks</h2>
        {smallTasks.map(task => (
          <TaskPreview key={task.id} task={task} />
        ))}
      </Box>

      <Box>
        <h2>Big tasks</h2>
        {bigTasks.map(task => (
          <TaskPreview key={task.id} task={task} />
        ))}
      </Box>
    </Box>
    <SizingTasksHelperDialog
      open={showHelperDialog}
      onCloseHandler={() => setShowHelperDialog(false)}
    />
    <ProceedDialog
      open={!uncategorizedTodos.length}
      onProceedHandler={proceed}
    >
      Great! All tasks have a size now!
    </ProceedDialog>
    </>
  )
}

export default TodoListCategorizer;