import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { createContext, useEffect, useState } from "react";
import { setUpUser } from "../util/database/firestore.access";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { firestore } from "../util/firestore.setup";
import { auth } from "../util/auth.setup";

export const UserContext = createContext();

export const UserProvider = props => {
  const [ user, setUser ] = useState(auth.currentUser);
  const [ userDetails, setUserDetails ] = useState({ name: String("") })
  const loggedIn = JSON.parse(localStorage.getItem("loggedIn"))

  useEffect(() => {
    const unsubAuth = auth.onAuthStateChanged((user) => {
      if(user) localStorage.setItem("loggedIn", JSON.stringify(true))
      else localStorage.removeItem("loggedIn")
      setUser(user)
    })

    if(!user) return

    const unsubscribe = onSnapshot(doc(firestore, "users", user.uid),
      userDetails => setUserDetails(userDetails.data())
    );

    return () => {
      unsubscribe()
      unsubAuth()
    }
  }, [user])

  const registerUser = (email, password) => {
    return new Promise((resolve, reject) => {
      createUserWithEmailAndPassword(auth, email, password)
        .then(async (userCredential) => {
          const currentUser = {
            ...userCredential.user,
            name: email
          }
          setUser(currentUser)
          localStorage.setItem('currentUser', JSON.stringify(currentUser));
          // Should this also be checked with then and catch?
          setUpUser(userCredential.user)
          resolve(user);
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  const logInUser = (email, password) => {
    return new Promise((resolve, reject) => {
      signInWithEmailAndPassword(auth, email, password)
        .then(async (userCredential) => {
          const details = await getUserDetails(userCredential.user);
          const currentUser = {
            ...userCredential.user,
            ...details
          }
          setUser(currentUser)
          localStorage.setItem('currentUser', JSON.stringify(currentUser));
          resolve(userCredential)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  const logOutUser = () => {
    auth.signOut()
    setUser(null);
  }

  const getUserDetails = async (user) => {
    if(!user) return ({ name: "no user" })
    const userDocRef = doc(firestore, "users", user.uid);
    const userDetailsSnap = await getDoc(userDocRef);
    return userDetailsSnap.data()
  }


  const setUserObject = (user) => {
    console.log(user)
    localStorage.setItem('currentUser', JSON.stringify(user))
    setUser(user)
  }

  return (
    <UserContext.Provider value={{
      user,
      setUser,
      setUserObject,
      logInUser,
      registerUser,
      logOutUser,
      getUserDetails,
      userDetails,
      loggedIn
    }}>
      { props.children }
    </UserContext.Provider>
  );
}