import { doc, collection, addDoc, query, getDocs, updateDoc, where, getDoc, deleteDoc } from "firebase/firestore"
import { firestore } from "../firestore.setup";

const USER_DATA = "userData";
const TASKS = "tasks";
const ROLES = "roles";
const TODO_LISTS = "todoLists";
const TODOS = "todos";

export const createTask = (name, userId) => {
  const taskObject = {
    name,
    created: new Date(),
    parentId: null,
    roleId: null,
    completed: false,
  }

  const taskCollection = collection(firestore, USER_DATA, userId, TASKS)
  addDoc(taskCollection, taskObject);
}

export const getUncategorizedTasks = async (userId) => {
  const taskCollection = collection(firestore, USER_DATA, userId, TASKS);
  const q = query(taskCollection, where("roleId", "==", null));
  const querySnapshot = await getDocs(q);

  const data = querySnapshot.docs.map(doc => ({
    id: doc.id,
    ...(doc.data())
  }))

  return data;
}

export const getAllIncompletedTasks = async (userId) => {
  const taskCollectionRef = collection(firestore, USER_DATA, userId, TASKS);
  const q = query(taskCollectionRef, where("completed", "==", false));
  const querySnapshot = await getDocs(q);

  const tasks = querySnapshot.docs.map(doc => ({
    id: doc.id,
    ...(doc.data())
  }))

  return tasks;
}

export const getAllRoles = async (userId) => {
  const roleCollectionRef = collection(firestore, USER_DATA, userId, ROLES);
  const q = query(roleCollectionRef);
  const querySnapshot = await getDocs(q);

  const roles = querySnapshot.docs.map(doc => ({
    id: doc.id,
    ...(doc.data())
  }));
  return roles;
}

export const createRole = async (name, userId) => {
  const roleCollectionRef = collection(firestore, "userData", userId, ROLES);
  addDoc(roleCollectionRef, {
    name,
    description: 'role in firestore'
  })
}

export const assignTaskToRole = async (taskToAssign, role, userId) => {
  const taskDocumentRef = doc(firestore, "userData", userId, "tasks", taskToAssign.id)
  await updateDoc(taskDocumentRef, {
    roleId: role.id
  })
}

export const performToggleNextWeekFlagging = async (task, userId) => {
  const candidate = !task.candidate;
  const taskDocumentRef = doc(firestore, "userData", userId, "tasks", task.id);
  const { id, ...updatedObject } = task;
  await updateDoc(taskDocumentRef, {...updatedObject, candidate});
}

export const getNextWeekCandidates = async (userId) => {
  const taskCollectionRef = collection(firestore, "userData", userId, "tasks");
  const q = query(taskCollectionRef, where("completed", "!=", true));
  const querySnapshot = await getDocs(q);
  const tasks = querySnapshot.docs.map(doc => ({
    id: doc.id,
    ...(doc.data())
  }))
  return tasks.filter(t => t.candidate);
}

export const getCurrentTodoListMetadata = async (userId) => {
  const todoListCollectionRef = collection(firestore, USER_DATA, userId, TODO_LISTS);
  const querySnapshot = await getDocs(query(todoListCollectionRef, where("finished", "==", false)))
  
  if(!querySnapshot.docs.length) return null;

  return { id: querySnapshot.docs[0].id, ...(querySnapshot.docs[0].data()) };
}

export const getCurrentTodoList = async (userId) => {
  const todoListMetadata = await getCurrentTodoListMetadata(userId);

  if(!todoListMetadata) return null;

  const todosCollectionRef = collection(firestore, USER_DATA, userId, TODOS);
  const querySnapshot = await getDocs(query(todosCollectionRef,
    where("todoListId", "==", todoListMetadata.id)
  ));

  const todoReferences = querySnapshot.docs.map(doc => ({
    id: doc.id,
    ...(doc.data())
  }))

  // merge tasks into the todo object
  const todos = todoReferences.map(async ref => {
    const taskDocRef = doc(firestore, USER_DATA, userId, TASKS, ref.taskId);
    const taskObject = await getDoc(taskDocRef)
    return {
      ...taskObject.data(),
      ...ref,
      id: ref.id
    }
  })
  
  return await Promise.all(todos)
}

export const setTaskSize = async (task, taskSize, userId) => {
  const taskDocumentRef = doc(firestore, USER_DATA, userId, TASKS, task.id)
  await updateDoc(taskDocumentRef, {
    taskSize
  })
}

export const createTodoList = async (tasks, userId) => {
  const todoListCollectionRef = collection(firestore, USER_DATA, userId, TODO_LISTS);
  const todoList = {
    created: new Date(),
    finished: false
  }
  const todoListDocRef = await addDoc(todoListCollectionRef, todoList);

  const todos = tasks.map(task => ({
    taskId: task.id,
    task,
    done: false,
    todoListId: todoListDocRef.id
  }))

  const todosCollectionRef = collection(firestore, USER_DATA, userId, TODOS);
  todos.forEach(todo => addDoc(todosCollectionRef, todo));
}

export const toggleIsDone = async (todo, userId) => {
  const todoDocRef = doc(firestore, USER_DATA, userId, TODOS, todo.id);
  await updateDoc(todoDocRef, { done: !todo.done });
}

export const updateTask = (updated, userId) => {
  const taskDocumentRef = doc(firestore, "userData", userId, "tasks", updated.id);
  const {id, ...updatedObject} = updated;
  updateDoc(taskDocumentRef, updatedObject);
}

export const updateRole = (updated, userId) => {
  const roleDocumentRef = doc(firestore, "userData", userId, "roles", updated.id);
  const {id, ...updatedObject} = updated;
  updateDoc(roleDocumentRef, updatedObject)
}

export const deleteRole = (role, userId) => {
  const roleDocumentRef = doc(firestore, "userData", userId, "roles", role.id)
  deleteDoc(roleDocumentRef)
}

export const deleteTask = (task, userId) => {
  const taskDocumentRef = doc(firestore, "userData", userId, "tasks", task.id)
  deleteDoc(taskDocumentRef)
}

export const finishTodoList = async (userId) => {
  const todoListCollectionRef = collection(firestore, USER_DATA, userId, TODO_LISTS)
  const querySnapshot = await getDocs(query(todoListCollectionRef, where("finished", "==", false)))
  if(!querySnapshot.docs.length) return null; // throw an error
  const todoListDocRef = doc(firestore, USER_DATA, userId, TODO_LISTS, querySnapshot.docs[0].id)
  updateDoc(todoListDocRef, { finished: true })
  
  // get all todo references
  const todosCollectionRef = collection(firestore, USER_DATA, userId, TODOS);
  const todosSnapshot = await getDocs(query(todosCollectionRef, where("todoListId", "==", querySnapshot.docs[0].id)))
  
  todosSnapshot.docs.forEach(todo => {
    const taskDocRef = doc(firestore, USER_DATA, userId, TASKS, todo.data().taskId);
    updateDoc(taskDocRef, { completed: todo.data().done });
  })
}