import { useState } from "react";
import './role-creator.styles.css';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { Card, CardContent, Typography } from "@mui/material";
import CreateRoleDialog from "../create-role-dialog/create-role-dialog.component";

const RoleCreator = () => {
  const [ openCreateRoleDialog, setOpenCreateRoleDialog] = useState(false)

  return (
    <>
    <Card
      sx={{ width: '350px', margin: 'auto', cursor: 'pointer' }}
      onClick={() => setOpenCreateRoleDialog(true)}
    >
      <CardContent
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '-10px' 
        }}
      >
        <Typography>Create a new role</Typography><AddBoxIcon />
      </CardContent>
    </Card>
    <CreateRoleDialog
      open={openCreateRoleDialog}
      onCloseHandler={() => {
        setOpenCreateRoleDialog(false)
      }}
    />
    </>
  )
}

export default RoleCreator;