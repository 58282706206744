import { Box, Card, CircularProgress, Grid } from "@mui/material";
import BrainDumpCard from "../../components/brain-dump-card/brain-dump-card.component";
import { useContext } from "react";
import CurrentWeekCard from "../../components/current-week-card/current-week-card.component";
import StartNewWeekCard from "../../components/start-new-week-card/start-new-week-card.component";
import AuthPage from "../auth-page/auth-page.component";
import { UserContext } from "../../contexts/user.context";
import { TaskContext } from "../../contexts/task.context";
import "./landing-page.styles.css"

const LandingPage = () => {
  const { user, loggedIn } = useContext(UserContext);
  const { todoListReference } = useContext(TaskContext)

  if(!user && !loggedIn) return(
    <Card
      sx={{
        width: '80%',
        margin: 'auto',
        padding: '15px',
        paddingBottom: '30px'
      }}
    >
      <AuthPage />
    </Card>
  );

  return (
    <Box sx={{ marginBottom: '20px' }}>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
        <Card
          className="brain-dump-card-container"
          sx={{ maxWidth: '300px' }}>
            <BrainDumpCard />
        </Card>
        </Grid>
        <Grid item>
          <Card
            sx={{ minWidth: '300px', maxWidth: '300px', height: '400px' }}>
              {todoListReference && <CurrentWeekCard />}
              {(todoListReference === null) && <StartNewWeekCard />}
              {todoListReference === undefined &&
                <div style={{
                  height: '100%',
                  display: "flex",
                  justifyContent: 'space-around',
                  alignItems: 'center'
                }}>
                  <CircularProgress />
                </div>}
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}

export default LandingPage;