import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

const ReviewListHelperDialog = ({open, onCloseHandler}) => {
  return (
    <Dialog
      open={open}
      onClose={onCloseHandler}
    >
      <DialogTitle>
        Review your list
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you looking forward to your week? You can, because you've just carefully
          prepared your week and made it easy for you to start.
          Something wrong about it? You can still make some adjustments to it.
          If it feels overwhelming because it's too much? Get rid of a task.
          Something is actually not a big task? Move it.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseHandler}>CLOSE</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ReviewListHelperDialog;