import { useContext, useState } from "react"
import { UserContext } from "../../contexts/user.context"
import { Typography } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import EditUsernameDialog from "../../components/edit-username-dialog/edit-username-dialog";

const UserProfilePage = () => {
  const { user, userDetails } = useContext(UserContext);
  const [ usernameDialogOpen, setUsernameDialogOpen ] = useState(false)

  if(!user) return ("loading")

  return (
    <>
      <Typography variant="h3">Profile</Typography>
      <Typography>
        Your username is <span style={{ fontWeight: 'bold' }}>{userDetails.name}</span>
        <EditIcon
          onClick={() => setUsernameDialogOpen(true)}
          style={{ fontSize: '15px', marginLeft: '5px', cursor: 'pointer' }}
        />
      </Typography>
      <Typography>Email: <span style={{ fontWeight: 'bold'}}>{user.email}</span></Typography>

      <EditUsernameDialog
        username={userDetails.name}
        open={usernameDialogOpen}
        onCloseHandler={() => {
          setUsernameDialogOpen(false)
        }}
      />
    </>
  )
}

export default UserProfilePage;