import { Alert, Box, Button, CardContent, Snackbar, TextField, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { TaskContext } from "../../contexts/task.context";
import { createTask } from "../../util/database/dexie.access";
import { UserContext } from "../../contexts/user.context";

const BrainDumpCard = () => {
  const [ newTaskName, setNewTaskName ] = useState('');
  const [ showConfirmSnackbar, setShowConfirmSnackbar ] = useState(false);
  const [ snackbarTaskName, setSnackbarTaskName ] = useState('')
  const { tasks } = useContext(TaskContext);
  const { user } = useContext(UserContext);

  let inboxCount = undefined;
  
  if(tasks)
    inboxCount = tasks.filter(t => !t.roleId).length;

  const handleClose = () => setShowConfirmSnackbar(false);

  return (<CardContent>
    <Typography color="primary" variant="h5">
      Brain Dump
    </Typography>
    <Typography color="text.secondary">
      Something on your mind? Just enter a goal,
      task or thought you want to track, and it will land
      in your Inbox.
    </Typography>
    <Box style={{ paddingTop: '10px' }}>
      <form onSubmit={(event) => {
        event.preventDefault()
        createTask(newTaskName, user.uid)
        setSnackbarTaskName(newTaskName)
        setShowConfirmSnackbar(true)
        setNewTaskName('')
      }}>
        <TextField
          size="small"
          fullWidth
          style={{ fontSize: '10px', marginBottom: '20px' }}
          id="filled-basic"
          label="Task, project, goal, ..."
          variant="standard"
          autoComplete="off"
          value={newTaskName}
          onChange={(event) => {
            setNewTaskName(event.target.value)
          }}
        />
        <Button
          onClick={() => {
            createTask(newTaskName, user.uid)
            setNewTaskName('')
          }}
          style={{ display: 'block' }}
          variant="outlined">ADD TO INBOX
        </Button>
      </form>
    </Box>
    <Typography color="text.secondary">
      Items in your inbox: {inboxCount !== undefined ? inboxCount : 'loading...'} 
    </Typography>
    <Snackbar open={showConfirmSnackbar} autoHideDuration={2000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
        Successfully added <Typography variant="inherit" component="span" sx={{ fontWeight: 'bold' }}>{snackbarTaskName}</Typography>!
      </Alert>
    </Snackbar>
  </CardContent>)
}

export default BrainDumpCard;