import { Alert, Snackbar, Typography } from "@mui/material"

const TaskCompletedSnackbar = ({openDoneSnackbar, onCloseHandler, doneTaskName}) => {
  return (
    <Snackbar open={openDoneSnackbar} autoHideDuration={2000} onClose={onCloseHandler}>
      <Alert onClose={onCloseHandler} severity="success" sx={{ width: '100%' }}>
        <Typography variant="inherit" component="span" sx={{ fontWeight: 'bold' }}>{doneTaskName}</Typography> completed!
      </Alert>
    </Snackbar>
  )
}

export default TaskCompletedSnackbar;