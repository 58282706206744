import { useEffect, useState } from 'react';
import './editable-task.styles.css';
import { Box, Card, CardContent, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import EditTaskDialog from '../edit-task-dialog/edit-task-dialog.component';

const EditableTask = ({ task }) => {
  const [ showEditDialog, setShowEditDialog ] = useState(false)
  const [ taskObject, setTaskObject ] = useState(task)

  useEffect(() => setTaskObject(task), [task])

  return (
    <>
    <Card
      sx={{
        backgroundColor: '#efefef',
        margin: '10px auto',
        maxWidth: '350px',
        maxHeight: '150px',
      }}>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography>{taskObject.name}</Typography>
          <EditIcon
            onClick={() => setShowEditDialog(true)}
            sx={{ maxWidth: '20px', cursor: 'pointer' }}
          />
        </Box>
      </CardContent>
    </Card>

    <EditTaskDialog
      open={showEditDialog}
      task={task}
      onCloseHandler={(updatedTask) => {
        setTaskObject(updatedTask)
        setShowEditDialog(false)
      }}
    />
    </>
  )
}

export default EditableTask;