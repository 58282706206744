import { Box, Button, TextField, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { UserContext } from "../../contexts/user.context";
import "./auth-page.styles.css"


// add 'real-time checking' for existing usernames
const AuthPage = () => {
  const { user, registerUser, logInUser } = useContext(UserContext);

  const [ signUpForm, setSignUpForm ] = useState({ email: '', password: '', passwordConfirm: '' })
  const [ signInForm, setSignInForm ] = useState({ email: '', password: ''})
  const [ emailInUse, setEmailInUse ] = useState(false);
  const [ malformedPassword, setMalformedPassword ] = useState(false)
  const [ showPasswordPolicy, setShowPasswordPolicy ] = useState(false)
  const [ badCredentials, setBadCredentials ] = useState(false);

  const performRegistration = () => {
    if(signUpForm.password !== signUpForm.passwordConfirm) {
      // TODO create an error snackbox
      return;
    }
    // Password must contain
    // - one digit
    // - one lowercase letter
    // - one uppercase letter
    // - one underscore
    // - no space
    // 8-16 characters long. Usage of any other special character other than underscore is optional.
    const regex = /^(?=.*[0-9])(?=.*[!@#$%^&*_()])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*_()]{6,16}$/;
    if(!regex.test(signUpForm.password)) {
      setMalformedPassword(true)
      setShowPasswordPolicy(true)
      return;
    }
    registerUser(signUpForm.email, signUpForm.password).catch((error) => {
      if(error.code === "auth/email-already-in-use") setEmailInUse(true);
    })
  }

  const performLogin = () =>
    logInUser(signInForm.email, signInForm.password).catch((error) => {
      if(error.code === "auth/invalid-credential") setBadCredentials(true)
    })

  if(user) return (
    <>
      <h1>Logged in user</h1>
      <Box>
        {JSON.stringify(user, null, 2)}
      </Box>
    </>
  )

  return (
    <div className="auth-page-container">
      <form className="auth-form" onSubmit={(e) => {
        e.preventDefault()
        performRegistration()
      }}>
        <Typography color="primary" variant="h4">Sign up</Typography>
        <div className="auth-field">
          <TextField
            size="small"
            name="email" label="E-Mail"
            error={ emailInUse ? true : false }
            helperText={ emailInUse ? "email already in use" : false }
            onChange={(e) => {
              setEmailInUse(false)
              setSignUpForm({ ...signUpForm, [e.target.name]: e.target.value })
            }}
          >
            { signUpForm.username }
          </TextField>
        </div>
        <br />
        <div className="auth-field">
          <TextField
            size="small"
            name="password" label="Password" type="password"
            error={malformedPassword}
            onChange={(e) => {
              setMalformedPassword(false)
              setSignUpForm({ ...signUpForm, [e.target.name]: e.target.value })
            }}
            >
            { signUpForm.password }
          </TextField>
          {showPasswordPolicy &&
            <Typography color="error" sx={{ fontSize: '12px'}}>
              Password has to have one digit [0-9], a special character [!@#$%^&*],
              and has to be 6-16 characters long.
            </Typography>}
        </div>
        <br />
        <div className="auth-field">
          <TextField
            size="small"
            error={ signUpForm.password !== signUpForm.passwordConfirm }
            helperText={ signUpForm.password !== signUpForm.passwordConfirm ? "passwords don't match" : false }
            name="passwordConfirm" label="Confirm password" type="password"
            onChange={(e) => setSignUpForm({ ...signUpForm, [e.target.name]: e.target.value })}
            >
            { signUpForm.passwordConfirm}
          </TextField>
          </div>
        <br />
        <Button
          type="submit"
        >Sign up!</Button>
      </form>
      <div className="hr-container"><hr /></div>
      <hr />
      <form className="auth-form" onSubmit={(e) => {
        e.preventDefault()
        performLogin()
      }}>
        <Typography color="primary" variant="h4">Sign in</Typography>
        <div className="auth-field">
          <TextField
            size="small"
            name="email" label="E-Mail"
            error={badCredentials}
            onChange={(e) => {
              setBadCredentials(false)
              setSignInForm({ ...signInForm, [e.target.name]: e.target.value })
            }}
            >
            { signInForm.email }
          </TextField>
        </div>
        <br />
        <div className="auth-field">
          <TextField
            size="small"
            name="password" label="Password" type="password"
            error={badCredentials}
            onChange={(e) => {
              setBadCredentials(false)
              setSignInForm({ ...signInForm, [e.target.name]: e.target.value })
            }}
            >
            { signInForm.password }
          </TextField>
        </div>
        {badCredentials ? <Typography color="error">Bad credentials!</Typography> : ''}
        <Button type="submit">Sign in!</Button>
      </form>
    </div>
  )
}

export default AuthPage;