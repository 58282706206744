import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"

const SortingToRoleHelperDialog = ({open, onCloseHandler}) => {
  return (
    <Dialog
      open={open}
      onClose={onCloseHandler}
    >
      <DialogTitle>
        Sorting your task to a role
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          If you don't have any roles yet - create some that fit your needs.
          What about The Fitness Guy, The Selfcarer, The Reader, The Adventurer, ...?<br/>
          Once you have some roles set up, you think about the task presented to you
          and select the role you think it should be assigned to. No matching role yet?
          Create a new one!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseHandler}>CLOSE</Button>
      </DialogActions>
    </Dialog>
  )
}

export default SortingToRoleHelperDialog;