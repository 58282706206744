import { Badge, Typography } from "@mui/material"
import { useContext } from "react"
import { TaskContext } from "../../contexts/task.context"
import { useNavigate } from "react-router-dom"
import "./mindmap.style.css"

const MARGIN_BOTTOM = 15
const HEIGHT = 40
const WIDTH = 120
const MAX_DISTANCE = 40
const CURVE_DISTANCE = 50
const center = {x: 150, y: 220}
const startpoint = { x: 150 + center.x, y: 100 + center.y }

const MindmapSite = () => {
  const { roles, tasks } = useContext(TaskContext);
  const navigate = useNavigate();

  if(roles === undefined) return <>Loading...</>
  if(tasks === undefined) return <>Loading...</>

  const rolesPerSide = Math.ceil(roles.length / 2)
  let offset = 0
  if(rolesPerSide % 2 === 0) offset = -HEIGHT / 2
  let top = offset +  Math.floor(rolesPerSide / 2) * (HEIGHT + MARGIN_BOTTOM);

  const positions = [];
  for(let i = 0; i < rolesPerSide; i++) {
    const distanceVariation = i < rolesPerSide/2 ? i : rolesPerSide - i - 1
    positions.push({
      x: MAX_DISTANCE * distanceVariation * 0.5,
      y: -top + i * 2 * (HEIGHT + MARGIN_BOTTOM) / 2
    })
  }

  const allPositions = positions.concat(positions)

  const MAGIC_TOP = 100 - Math.ceil((rolesPerSide - 2) / 2) * 20
    - Math.ceil((rolesPerSide - 1) / 2) * 35

  return (
  <>
    <Typography sx={{ textAlign: 'center' }} variant="h4">Mindmap</Typography>
    <div className="mindmap-mobile">Mindmap is not available on mobile devices</div>
    <div className="mindmap">
      <div
        className="svg-container"
      >
        <svg className="svg-element">
          {roles.map((r, index) => {
            // refactor properly... center is not the center.. weird offset
            const endpoint = {
              x: (index < rolesPerSide) ?
                center.x + 65 - allPositions[index].x :
                center.x + 235 + allPositions[index].x,
              y: allPositions[index].y+top+MAGIC_TOP+center.y
            }

            const control1 = {
              x: (index < rolesPerSide) ?
                center.x + 65 + CURVE_DISTANCE - allPositions[index].x :
                center.x + 235 - CURVE_DISTANCE + allPositions[index].x,
              y: allPositions[index].y+top+MAGIC_TOP+center.y
            }

            const control2 = {
              x: (index < rolesPerSide) ?
                center.x + 65 + CURVE_DISTANCE - allPositions[index].x :
                center.x + 235 - CURVE_DISTANCE + allPositions[index].x,
              y: allPositions[index].y+top+MAGIC_TOP+center.y
            }

            return (<path
              key={r.id}
              d={`M ${startpoint.x} ${startpoint.y} C ${control1.x} ${control1.y}, ${control2.x} ${control2.y}, ${endpoint.x} ${endpoint.y}`}
              fill="none"
              stroke="brown"
              strokeWidth="2"
            />)
          })}
        </svg>
      </div>
      <div className="me"
        style={{
          height: `${HEIGHT}px`,
          lineHeight: `${HEIGHT}px`,
          width: `${WIDTH}px`,
        }}
      >ME
        {roles.map((r, index) => 
          <div
            onClick={() => navigate(`/mindmap/${r.id}`)}
            key={r.id}
            className="role"
            style={{
              height: `${HEIGHT}px`,
              lineHeight: `${HEIGHT}px`,
              width: `${WIDTH}px`,
              top: `${allPositions[index].y}px`,
              left: (index < rolesPerSide) ?
                `${-140 - allPositions[index].x}px` :
                `${140 + allPositions[index].x}px` }}>
            <Badge showZero badgeContent={tasks.filter(t => t.roleId === r.id).length || 0} color="secondary">
              {r.name}
            </Badge>
          </div>
        )}
      </div>
    </div>
  </>
  )
}

export default MindmapSite;