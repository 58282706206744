import { Alert, Snackbar, Typography } from "@mui/material"

const TaskSelectedSnackbar = ({open, taskName, onCloseHandler}) => {
  return (
    <Snackbar open={open} onClose={onCloseHandler} autoHideDuration={2000}>
      <Alert>Selected <Typography sx={{display: 'inline'}} fontWeight="bold">{taskName}</Typography></Alert>
    </Snackbar>
  )
}

export default TaskSelectedSnackbar;