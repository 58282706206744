import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

const SizingTasksHelperDialog = ({open, onCloseHandler}) => {
  return (
    <Dialog
      open={open}
      onClose={onCloseHandler}
    >
      <DialogTitle>
        Sizing tasks
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Great! You've done some very important proiritising! Now we'll find out
          which tasks are heavy! And I don't necessarily mean time heavy.
          A big task could either be something time expensive but also be something
          you really, really, really don't want to do. That could be a phone call,
          apologizing to someone, doing your taxes, ...
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseHandler}>CLOSE</Button>
      </DialogActions>
    </Dialog>
  )
}

export default SizingTasksHelperDialog;