import { useContext, useState } from "react";
import { TaskContext } from "../../contexts/task.context";
import { Card, CardContent, FormControl, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import * as Firestore from "../../util/database/dexie.access"
import { UserContext } from "../../contexts/user.context";
import EditTaskDialog from "../../components/edit-task-dialog/edit-task-dialog.component";

const Inbox = () => {
  const { tasks, roles } = useContext(TaskContext);
  const { user } = useContext(UserContext);
  const [ dialoges, setDialoges ] = useState({openEdit: false, task: null})

  if(tasks === undefined) return <>Loading...</>
  if(roles === undefined) return <>Loading...</>

  const inboxTasks = tasks.filter(t => !t.roleId)

  function dateDiffInDays(date1, date2) {
    const date1Ms = date1.getTime();
    const date2Ms = date2.getTime();
    const differenceMs = date2Ms - date1Ms;
    const differenceDays = Math.floor(differenceMs / (1000 * 60 * 60 * 24));
    return differenceDays;
  }

  const handleRoleAssignment = (event, task) => {
    const roleId = event.target.value
    Firestore.assignTaskToRole(task, { id: roleId }, user.uid)
  }

  const closeEditDialog = () => setDialoges({ openEdit: false })

  return (
    <Card sx={{ width: '80%', margin: 'auto' }}>
      <CardContent>
        <Typography color="primary" variant="h4">Inbox</Typography>        
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>Name</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Created</TableCell>
                <TableCell sx={{ fontWeight: 'bold', textAlign: "right" }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {inboxTasks.map(t => {
              const date = t.created.toDate();
              const daysAgo = dateDiffInDays(date, new Date());
              return (<TableRow key={t.id}>
                <TableCell>{t.name}</TableCell>
                <TableCell>
                  {daysAgo === 0 ? "today" : `${daysAgo} days ago`}
                </TableCell>
                <TableCell sx={{ textAlign: 'right', alignItems: 'center', lineHeight: '26px' }}>
                  <FormControl
                    variant="standard"
                    style={{ minWidth: '120px', float: 'right' }}
                    >
                    <Select 
                      size="small"
                      defaultValue={0}
                      onChange={(event) => handleRoleAssignment(event, t)}
                    >
                      <MenuItem value={0}> :: sort to role :: </MenuItem>
                      {roles.map(r =>
                        <MenuItem key={r.id} value={r.id}>{r.name}</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                  <span
                    className="pointer"
                    onClick={() => {
                      setDialoges({openEdit: true, task: t})
                    }}
                  >
                    edit
                  </span>
                </TableCell>
              </TableRow>)
            })}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <EditTaskDialog open={dialoges.openEdit} onCloseHandler={closeEditDialog} task={dialoges.task} />

    </Card>
  )
}

export default Inbox;