import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { useContext, useState } from "react";
import * as IndexedDbData from "../../util/database/dexie.access"
import { UserContext } from "../../contexts/user.context";
import { TaskContext } from "../../contexts/task.context"

const CreateRoleDialog = ({open, onCloseHandler}) => {
  const [ roleName, setRoleName ] = useState('')
  const [ roleExists, setRoleExists ] = useState(false)
  const { user } = useContext(UserContext);
  const { roles } = useContext(TaskContext);

  return (
    <Dialog
      open={open}
      onClose={(event) => {
        if(event && event.key === "Escape") {
          onCloseHandler(null)
          setRoleName('')
          return
        }

        onCloseHandler(roleName)
        setRoleName('')
    }}
    >
      <DialogTitle>New role</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Name your new role:
        </DialogContentText>
        <form
          onSubmit={(e) =>{
            e.preventDefault()
            if(roleExists) return
            onCloseHandler(roleName)
            if(roleName) IndexedDbData.createRole(roleName, user.uid);
            setRoleName('')
          }}
        >
          <TextField
            sx={{ marginTop: '10px' }}
            autoComplete="off"
            label="Role name"
            id="role_name"
            error={roleExists}
            helperText={roleExists && "This Role already exists"}
            value={roleName}
            onChange={(e) => {
              setRoleName(e.target.value)
              if(roles.find(r => r.name === e.target.value)) setRoleExists(true)
              else setRoleExists(false)
            }}
          />
          <br />
          <Button type="submit">Create</Button>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default CreateRoleDialog;