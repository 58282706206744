export const getWeekNumber = (date) => {
  const newDate = new Date(date);
  newDate.setUTCDate(newDate.getUTCDate() + 4 - (newDate.getUTCDay() || 7));
  const yearStart = new Date(Date.UTC(newDate.getUTCFullYear(), 0, 1));
  const weekNumber = Math.ceil((((newDate - yearStart) / 86400000) + 1) / 7);
  return weekNumber;
}

export const dateDiffInDays = (date1, date2) => {
  // Convert both dates to milliseconds
  const date1Ms = date1.getTime();
  const date2Ms = date2.getTime();

  // Calculate the difference in milliseconds
  const differenceMs = date2Ms - date1Ms;

  // Convert the difference in milliseconds to days
  const differenceDays = Math.floor(differenceMs / (1000 * 60 * 60 * 24));

  return differenceDays;
}