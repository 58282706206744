import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
        main: '#ff5e62',
    },
    secondary: {
        main: '#ff9966',
    },
  },
});