import { Typography } from "@mui/material"

const Settings = () => {
  return (
    <>
      <Typography variant="h4">Settings</Typography>
      <Typography>Nothing to customise yet.</Typography>
    </>
  )
}

export default Settings;