import { useContext, useEffect, useState } from "react";
import EditableTask from "../../components/editable-task/editable-task.component";
import RoleSelection from "../../components/role-selection/role-selection.component";
import { Box, Typography } from "@mui/material";
import HelpIcon from '@mui/icons-material/Help';
import SortingToRoleHelperDialog from "../../components/sorting-to-role-helper-dialog/sorting-to-role-helper-dialog.component";
import { PrepareNextWeekContext } from "../../contexts/prepare-next-week.context";
import ProceedDialog from "../../components/proceed-dialog/proceed-dialog.component";
import { TaskContext } from "../../contexts/task.context";

const SortingToRole = () => {
  const { proceed } = useContext(PrepareNextWeekContext);
  const { roles, tasks } = useContext(TaskContext);
  const [ showHelperDialog, setShowHelperDialog] = useState(false)
  const [ showProceedDialog, setShowProceedDialog ] = useState(false)

  const taskWithoutRole = tasks.filter(t => !t.roleId)

  useEffect(() => {
    if(!taskWithoutRole.length) {
      setShowProceedDialog(true)
    }
  }, [taskWithoutRole])

  const taskToSort = taskWithoutRole.length !== 0 ? taskWithoutRole[0] : undefined

  return (
    <>
    <Typography
      onClick={() => setShowHelperDialog(true)}
      variant="h5" sx={{ textAlign: 'center', cursor: 'pointer' }}
    >
      Need help
      <HelpIcon />
    </Typography>

    {taskWithoutRole.length ? <>
      <Box sx={{ marginTop: '20px' }}>
        <Typography sx={{ textAlign: 'center' }}>Where does this task belong to?</Typography>
        <EditableTask task={taskToSort} />
      </Box>
      <hr />
      <RoleSelection
        roles={roles}
        taskToSort={taskToSort}
        refresh={() => {
        }}
      />
    </> : ''}

    <SortingToRoleHelperDialog
      open={showHelperDialog}
      onCloseHandler={() => setShowHelperDialog(false)}
    />

    <ProceedDialog
      open={showProceedDialog}
      onProceedHandler={() => {
        setShowProceedDialog(false)
        proceed()
      }}
    >
      Well done! You've sorted all your tasks to roles!
    </ProceedDialog>
    </>
  )
}

export default SortingToRole;