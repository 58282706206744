import { Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { TaskContext } from "../../contexts/task.context";
import DeleteTaskDialog from "../../components/delete-task-dialog/delete-task-dialog.component";
import { dateDiffInDays } from "../../util/date";
import EditTaskDialog from "../../components/edit-task-dialog/edit-task-dialog.component";

const Role = props => {
  const { roleId } = useParams();
  const [ dialoges, setDialoges ] = useState({ openEdit: false, openDelete: false, task: null })
  const { roles, tasks } = useContext(TaskContext);

  const closeDeleteDialog = () => setDialoges({openDelete: false})
  const closeEditDialog = () => setDialoges({openEdit: false})

  if(tasks === undefined) return <>Loading...</>
  if(!roles) return <>No roles to display</>
  const role = roles.find(r => r.id === roleId)
  if(!role) return <>Role does not exitst... or still loading.</>
  const taskInRole = tasks.filter(t => t.roleId === roleId)

  return (<Card sx={{ width: '90%', margin: 'auto' }}>
    <CardContent>
      <Typography color="primary" variant="h4">Role</Typography>
      <Typography color="primary" variant="h5">{role.name}</Typography>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Task</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Created</TableCell>
              <TableCell sx={{ fontWeight: 'bold', textAlign: 'right' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {taskInRole.map(t => {
              const daysAgo = dateDiffInDays(t.created.toDate(), new Date())
              const weeksAgo = Math.floor(daysAgo/7) || undefined;
              const monthsAgo = Math.floor(daysAgo/30) || undefined;
              const yearsAgo = Math.floor(daysAgo/356) || undefined;

              let createdText = daysAgo !== 1 ? `${daysAgo} days ago` : '1 day ago'
              if(daysAgo > 14) createdText = `${weeksAgo} months ago`
              if(daysAgo > 60) createdText = `${monthsAgo} months ago`
              if(daysAgo > 356) createdText = yearsAgo !== 1 ? `${yearsAgo} years ago` : '1 year ago'


              return (
                <TableRow key={t.id}>
                  <TableCell>{t.name}</TableCell>
                  <TableCell>{createdText}</TableCell>
                  <TableCell sx={{ textAlign: 'right' }}>
                    <span
                      className="pointer"
                      onClick={() => {
                        setDialoges({openEdit: true, task: t})
                      }}
                    >
                      edit
                    </span>
                    {` | `}
                    <span
                      className="pointer"
                      onClick={() => {
                        setDialoges({openDelete: true, task: t})
                      }}
                    >
                      delete
                    </span>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <EditTaskDialog open={dialoges.openEdit} onCloseHandler={closeEditDialog} task={dialoges.task} />
      <DeleteTaskDialog open={dialoges.openDelete} onCloseHandler={closeDeleteDialog} task={dialoges.task} />
    </CardContent>
  </Card>)
}

export default Role;