import { Typography, Card, CardContent, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Button } from "@mui/material"
import { useContext, useState } from "react";
import { TaskContext } from "../../contexts/task.context";
import { useNavigate } from "react-router-dom";
import CreateRoleDialog from "../../components/create-role-dialog/create-role-dialog.component";
import EditRoleDialog from "../../components/edit-role-dialog/edit-role-dialog.component";
import ConfirmDeleteRoleDialog from "../../components/archive-role-dialog/archive-role-dialog.component";

const Roles = () => {
  const { roles, tasks } = useContext(TaskContext)
  const [ openCreateRoleDialog, setOpenCreateRoleDialog ] = useState(false)
  const [ openEditRoleDialog, setOpenEditRoleDialog ] = useState(false)
  const [ openArchiveRoleDialog, setOpenArchiveRoleDialog ] = useState(false)
  const [ roleToEdit, setRoleToEdit ] = useState(undefined)
  const navigate = useNavigate();

  if(roles === undefined) return <>Loading...</>
  if(tasks === undefined) return <>Loading...</>

  return (
    <Card style={{ width: '80%', margin: 'auto' }}>
      <CardContent>
        <Typography color="primary" variant="h4">Roles</Typography>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }} >Name</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }} >Open tasks</TableCell>
                <TableCell sx={{ fontWeight: 'bold', textAlign: 'right'}} >Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {roles.map(r => {
                const openTasks = tasks.filter(t => t.roleId === r.id).length
                return (<TableRow key={r.id}>
                  <TableCell>
                    <span
                      onClick={() => { navigate(`/role/${r.id}`) }}
                      style={{ cursor: 'pointer' }}
                    >
                      {r.name}
                    </span>
                  </TableCell>
                  <TableCell>{openTasks}</TableCell>
                  <TableCell sx={{ textAlign: 'right' }}>
                    <span
                      className="pointer"
                      onClick={() => {
                        setRoleToEdit(r)
                        setOpenEditRoleDialog(true)
                      }
                    }>
                      edit
                    </span>
                    {` | `}
                    <span
                      className="pointer"
                      onClick={() => {
                        setRoleToEdit(r)
                        setOpenArchiveRoleDialog(true)
                      }}
                    >
                      archive
                    </span>
                  </TableCell>
                </TableRow>)
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Button onClick={() => setOpenCreateRoleDialog(true)}>Create role</Button>
      </CardContent>
      <CreateRoleDialog
        open={openCreateRoleDialog}
        onCloseHandler={() => setOpenCreateRoleDialog(false)}
      />
      <EditRoleDialog
        open={openEditRoleDialog}
        role={roleToEdit}
        onCloseHandler={() => setOpenEditRoleDialog(false)}
      />
      <ConfirmDeleteRoleDialog
        open={openArchiveRoleDialog}
        onCloseHander={() => setOpenArchiveRoleDialog(false)}
        role={roleToEdit}
      />
    </Card>
  )
}

export default Roles;