import { TASK_SIZE } from '../../util/database/constants';
import './task-categorizer.styles.css';
import * as IndexedDbData from '../../util/database/dexie.access'
import { Button, Card, CardContent, Typography } from '@mui/material';
import { useContext } from 'react';
import { UserContext } from '../../contexts/user.context';

const TaskCategorizer = ({ task }) => {
  const { user } = useContext(UserContext);

  const handleMarkAsBigTask = async (taskToMark) => {
    await IndexedDbData.setTaskSize(taskToMark, TASK_SIZE.BIG, user.uid);
  }

  const handleMarkAsSmallTask = async (taskToMark) => {
    await IndexedDbData.setTaskSize(taskToMark, TASK_SIZE.SMALL, user.uid);
  }

  return (
    <Card sx={{ width: '300px', height: '60px', marginBottom: '5px' }}>
      <CardContent
        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Button
          onClick={() => handleMarkAsBigTask(task)} type="button">
            big
        </Button>
        <Typography>{ task.name }</Typography>
        <Button onClick={() => handleMarkAsSmallTask(task)} type="button">small</Button>
      </CardContent>
    </Card>
  )
}

export default TaskCategorizer;