import { Outlet, useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import CurrentUserIcon from "../../components/current-user-icon/current-user-icon.component";
import "./navigation.styles.css"
import version from "../../util/version.json"

const Navigation = () => {
  const navigate = useNavigate()

  return(
    <>
      <Box style={{ paddingBottom: '20px' }}>
        <Typography
          sx={{ width: '200px', margin: 'auto', position: 'relative'}}
          className="logo"
          variant="h3"
        >
          <span
            style={{cursor: 'pointer'}}
            onClick={() => navigate('/')}
          >
            GYST <HomeIcon sx={{ paddingTop: '15px' }} fontSize="38px" />
          </span>
        </Typography>
        <CurrentUserIcon />
        {process.env.REACT_APP_ENVIRONMENT !== 'production' ?
          <div className="version">
            {process.env.REACT_APP_ENVIRONMENT.toUpperCase()}
            {` :: v`}
            {version.currentVersion}
            {` :: `}
            {version.versionName}
          </div> : ''}
      </Box>
      <Outlet />
    </>
  )
}

export default Navigation;